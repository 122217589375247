import React, { useState, useEffect } from "react";
import "./index.css";
import { FaWhatsapp } from "react-icons/fa";
import MotionText from "../../../extra/motionText";
import FormEstimate from "../../../common/formEstimate/FormEstimate";

function  UniversityBanner({uni}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = Array.isArray(uni.image) ? uni.image : []; // Check if uni.image is an array

  useEffect(() => {
    if (images.length > 1) {
      // Only start slideshow if there's more than one image
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(interval);
    }
  }, [images.length]);

  return (
    // <div className="w-full  md:px-0 bg-section relative overflow-hidden">
    //   <div className="absolute top-0 left-0 w-full h-full">
    //     {images?.map((image, index) => (
    //       <img
    //         key={index}
    //         src={image}
    //         alt={`img ${index}`}
    //         className={`w-full h-full absolute top-0 left-0 ${
    //           index === currentImageIndex ? "opacity-100" : "opacity-0"
    //         } transition-opacity duration-1000 ease-in-out`}
    //       />
    //     ))}
    //   </div>
    //   <div className="w-full h-full absolute top-0 left-0 bg-black opacity-60"></div>
    //   <div className="w-full h-full bg-opacity-50 bg-black px-5">
    //     <div className="container mx-auto py-8 md:py-20">
    //       <div className="relative pb-8 md:pb-20 flex flex-col md:flex-row justify-between items-center">
    //         <div className="mb-4 md:mb-0 md:me-6">
    //           <MotionText uni={uni} />
    //           <div className="text-black flex flex-wrap py-2">
    //             {uni &&
    //               uni.courses.map((course, index) => (
    //                 <p key={index} id={`course-${course.id}`} className=" uppercase px-2 mb-1 mr-2 bg-white rounded-md">
    //                   {course.name}
    //                 </p>
    //               ))}
    //           </div>
    //           <div className="flex flex-wrap mb-4 md:mb-0">
    //             <button
    //               type="button"
    //               className="focus:outline-none text-white bg-red-700 hover:bg-green-500 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2"
    //             >
    //               <a
    //                 className="text-white no-underline"
    //                 href="tel:+911234567890"
    //               >
    //                 Call now
    //               </a>
    //             </button>

    //             <button
    //               type="button"
    //               className="focus:outline-none text-white bg-green-500 hover:bg-red-700 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2"
    //             >
    //               <a
    //                 className="text-white flex items-center gap-1 no-underline"
    //                 href="tel:+911234567890"
    //               >
    //                 <span>Chat now </span> <FaWhatsapp />
    //               </a>
    //             </button>
    //           </div>
    //         </div>
    //         <div className="pe-7 md:pe-0">
    //           <FormEstimate />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="w-full md:px-0 bg-section relative overflow-hidden md:bg-hidden bg-black">
      <div className="md:hidden">
        <img></img>
      </div>
      <div className="absolute top-0 left-0 w-full md:h-full h-[38vh]">
        {images?.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`img ${index}`}
            className={`w-full h-full absolute top-0 left-0 ${
              index === currentImageIndex ? "opacity-100" : "opacity-0"
            } transition-opacity duration-1000 ease-in-out`}
          />
        ))}
      </div>
      <div className="w-full h-full absolute top-0 left-0 bg-black opacity-60"></div>
      <div className="w-full h-full bg-opacity-50 bg-black md:px-5 px-4 ">
        <div className="container mx-auto py-4 md:py-10">
          <div className="relative  pb-2 md:pb-12 flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0 md:me-6 ">
              <MotionText uni={uni} />
              <div className="text-black flex flex-wrap ">
                {uni &&
                  uni.courses.slice(0, 8).map((course) => (
                    <p
                      key={course.id}
                      id={`course-${course.id}`}
                      className="uppercase text-[10px] md:text-[15px] px-2 mb-1 mr-2 bg-white md:rounded-md rounded-sm">
                      {course.name}
                    </p>
                  ))}
              </div>
              <div className="flex flex-wrap mb-4 md:mb-0 mt-2 md:mt-2">
                <button
                  type="button"
                  className={`${uni.Color} focus:outline-none text-white  hover:bg-green-500 
              font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2`}>
                  <a
                    className="text-white no-underline"
                    href="tel:+911234567890">
                    Call now
                  </a>
                </button>

                <button
                  type="button"
                  className="focus:outline-none text-white bg-green-500 hover:bg-red-700 
              font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2">
                  <a
                    className="text-white flex items-center gap-1 no-underline"
                    href="tel:+911234567890">
                    <span>Chat now </span> <FaWhatsapp />
                  </a>
                </button>
              </div>
            </div>
            <div className="md:pe-7 sm:pe-2 mt-8 md:mt-0 pe-0 md:w-[40%] lg:w-[25%] w-[100%]">
              <FormEstimate uni={uni}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniversityBanner;

// import React from "react";
// import "./index.css";
// import { FaWhatsapp } from "react-icons/fa";
// import MotionText from "../../../extra/motionText";
// import FormEstimate from "../../../common/formEstimate/FormEstimate";

// function UniversityBanner({ uni }) {
//   return (
//     <div
//       style={{ backgroundImage: `url(${uni.image})` }}
//       className="w-full md:px-0 bg-section"
//     >
//       <div className="w-full h-full bg-opacity-50 bg-black px-5">
//         <div className="container mx-auto py-8 md:py-20">
//           <div className="relative pb-8 md:pb-20 flex flex-col md:flex-row justify-between items-center">
//             <div className="mb-4 md:mb-0 md:me-6">
//               <MotionText uni={uni} />
//               <div className="text-black flex flex-wrap py-2">
//                 {uni &&
//                   uni.courses.map((course, index) => (
//                     <p key={index} className=" uppercase px-2 mb-1 mr-2 bg-[rgb(255,223,21)]">
//                       {course.name}
//                     </p>
//                   ))}
//               </div>
//               <div className="flex flex-wrap mb-4 md:mb-0">
//                 <button
//                   type="button"
//                   className="focus:outline-none text-white bg-red-700 hover:bg-green-500 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2"
//                 >
//                   <a
//                     className="text-white no-underline"
//                     href="tel:+911234567890"
//                   >
//                     Call now
//                   </a>
//                 </button>

//                 <button
//                   type="button"
//                   className="focus:outline-none text-white bg-green-500 hover:bg-red-700 font-medium rounded-lg text-sm px-2 md:px-5 py-2.5 me-2 mb-2"
//                 >
//                   <a
//                     className="text-white flex items-center gap-1 no-underline"
//                     href="tel:+911234567890"
//                   >
//                     <span>Chat now </span> <FaWhatsapp />
//                   </a>
//                 </button>
//               </div>
//             </div>
//             <div className="pe-7 md:pe-0">
//               <FormEstimate />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UniversityBanner;
