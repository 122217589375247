import React from "react";

function Scholarships({uni}) {


  const backenddata = [
    { Header: "Scholarship Type", accessor: "College-specific" },
    { Header: "Organisation", accessor: uni.university },
    { Header: "Application Deadline", accessor: uni.applicationDeadline },
    { Header: "No. of Scholarships", accessor: uni.noScholarships },
    { Header: "Amount", accessor: uni.amount },
    { Header: "International Students", accessor: uni.internationalStudents },
    {
      Header: "Scholarship Link",
      accessor: uni.scholarshipLink,
      Cell: ({ value }) => (
        <a href={value} target="_blank" rel="noopener noreferrer">
          Apply Now
        </a>
      ),
    },
  ];

  return (
    <div className="w-full py-5 px-4 md:px-[0rem] flex justify-center">
      <div className="md:w-[90%] w-[100%]">
        <h2 className="text-blue-400 text-[20px] py-2">
          Scholarships For Students On <span className={`text-[${uni.textColor}]`}>The Basis Of Marks/CGPA</span>
        </h2>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm my-2 text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <tbody>
              {backenddata.map((mapdata, index) => (
                <tr
                  key={index}
                  className="bg-white border-b border-r dark:bg-gray-800 dark:border-gray-700"
                >
                  <td scope="col" className="px-6 py-3">
                    {mapdata.Header}
                  </td>
                  <td scope="col" className="px-6 py-3">
                    {mapdata.accessor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Scholarships;
