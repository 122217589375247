import React from "react";
import { IoLogoWhatsapp, IoMdClose } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import im1 from "./screen/home/courses/image/Courses/1.png";
import im2 from "./screen/home/courses/image/Courses/2.png";
import im3 from "./screen/home/courses/image/Courses/3.png";
import im4 from "./screen/home/courses/image/Courses/7.png";
import im5 from "./screen/home/courses/image/Courses/8.png";
import im6 from "./screen/home/courses/image/Courses/9.png";
import im7 from "./screen/home/courses/image/Courses/10.png";
import im8 from "./screen/home/courses/image/Courses/11.png";


import hy2 from "./images/haridwarUniversity/hiringPartner/Capgemini.gif";
import hy3 from "./images/haridwarUniversity/hiringPartner/Prism-Johnson-Limited-logo-.jpg";
import hy4 from "./images/haridwarUniversity/hiringPartner/brillio.png";
import hy5 from "./images/haridwarUniversity/hiringPartner/filo.png";
import hy6 from "./images/haridwarUniversity/hiringPartner/hoping_mind.jpeg";
import hy7 from "./images/haridwarUniversity/hiringPartner/planetspark-logo-po.png";
import hy8 from "./images/haridwarUniversity/hiringPartner/rubico.jpeg";
import hy9 from "./images/haridwarUniversity/hiringPartner/-mobiloittes-.png";



import pacifich1 from "./images/pacificUniversity/hiringPartner/1.jpg";
// import pacifich2 from "./images/pacificUniversity/hiringPartner/2.jpg";
// import pacifich3 from "./images/pacificUniversity/hiringPartner/3.jpg";
// import pacifich4 from "./images/pacificUniversity/hiringPartner/4.jpg";
// import pacifich5 from "./images/pacificUniversity/hiringPartner/5.jpg";
// import pacifich6 from "./images/pacificUniversity/hiringPartner/6.jpg";
// import pacifich7 from "./images/pacificUniversity/hiringPartner/7.jpg";
import pacifich8 from "./images/pacificUniversity/hiringPartner/8.jpg";
// import pacifich9 from "./images/pacificUniversity/hiringPartner/9.jpg";
import pacifich10 from "./images/pacificUniversity/hiringPartner/10.jpg";
import marwadie1 from "./images/marwadiUniversity/event/1 (1).jpg";
import marwadie3 from "./images/marwadiUniversity/event/1 (3).jpg";
import marwadie5 from "./images/marwadiUniversity/event/1 (5).jpg";
// import haridwar from "./images/haridwarUniversity/haridwar.jpg";
import anshubudha from "./images/haridwarUniversity/placedStudent/Anshu Budha.jpg";
import chandansharma from "./images/haridwarUniversity/placedStudent/Chandan Sharma.jpg";
import rachitkaushik from "./images/haridwarUniversity/placedStudent/Rachit Kaushik.jpg";
import sagargautam from "./images/haridwarUniversity/placedStudent/Sagar Gautam.jpg";
import shrutisaloni from "./images/haridwarUniversity/placedStudent/Shruti Saloni.jpg";
import shyamnarayan from "./images/haridwarUniversity/placedStudent/Shyam Narayan.jpg";

import BlogPost1 from "./screen/blog/blog1";
import BlogPost2 from "./screen/blog/blog2";
import PrivacyPolicy from "./screen/privacyPolicy";
import Navbar from "./screen/home/navbar";
import Enquriyform from "./common/enquiryform/page";
import Footer from "./screen/home/Footer/Footer";
import Disclaimer from "./disclaimer";
import mainbanner from "./images/roorkee_college_new.webp";
const App = () => {

  //   textColor:"#EA2284",
  //   university: "pacific University",
  //   image: [karnavati2,karnavati4],
  //   courses: [
  //     {
  //       name: "BBA(Hons)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "HR Management",
  //         "Digital Marketing",
  //         "Aviation",
  //         "Sports Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA
  //       description: "Description for BBA course goes here.",
  //     },
  //     {
  //       name: "MBA.",
  //       fee: "1,77,500/Year",
  //       details: ["2 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and research grants.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "Human Resource (HR)",
  //         "Entrepreneurship And Family Business",
  //         " Data Analytics & Business Intelligence",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im2, // image source for Ph.D.
  //       description: "Description for MBA program goes here.",
  //     },
  //     {
  //       name: "BA LLB (Hons.)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //         "Criminial Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im3, // image source for LLM
  //       description: "Description for LLM course goes here.",
  //     },
  //     {
  //       name: "BA (Hons )‐LA",
  //       fee: "2,20,000/Year",
  //       details: ["3+1 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and financial need.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for BA
  //       description: "Description for BA program goes here.",
  //     },
  //     {
  //       name: "BBA LLB(Hons.)",
  //       fee: "₹ 2,60,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         "Criminal Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA LLB
  //       description: "Description for BBA LLB course goes here.",
  //     },
  //     {
  //       name: "B.Tech",
  //       fee: "₹ 1,39,250/year",
  //       details: ["4 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Computer Science & Engineering",
  //         " CSE -AI & ML",
  //         "CSE- Cyber Security",
  //         " CSE-Data Science",
  //         " Information & Communication Technology",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im7, // image source for B.Tech
  //       description: "Description for B.Tech course goes here.",
  //     },
  //     {
  //       name: "B.S. (Hons) Computer Science",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus","Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Computer Science",
  //         "Data Science",
  //         " Artificial Intelligence & Machine Learning",
  //         "Forensic Science",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for M.Tech
  //       description: "Description for   B.S(Hons) course goes here.",
  //     },
  //     {
  //       name: "BCA",
  //       fee: "₹ 80,000/year",
  //       details: ["3 Year", "On-campus", "Full Time"],
  //       rating: 4.8,
  //       scholarship: "According to merit and need-based.",
  //       specialisation: [
  //         "Computer Applications",
  //         "Software Development",
  //         "Web Development",
  //         "Networking",
  //         "Database Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im4, // image source for BCA
  //       description: "Description for BCA course goes here.",
  //     },
  //     {
  //       name: "Integrated‐ B.S. +M.S. Cyber Security",
  //       fee: "₹ 2,20,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im5, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //     {
  //       name: "B.Com (Hons)‐LA ",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: ["Cyber Security"],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //   ],
  //   event: [
  //     {
  //       img: award1,
  //       data: "31",
  //       month: "may, 2023",
  //       hed: "Karnavati University felicitated with EducationWorld Award",
  //       pre: "We are elated to announce that Karnavati University has won the EducationWorld award under the category ‘Campus Design Excellence!",
  //       time: "May 31, 2023",
  //       country: "United Kingdom",
  //       alt: "Augmented Reality Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award2,
  //       data: "25",
  //       month: "jan, 2023",
  //       hed: "4 Star Ranking by Institution’s Innovation Council (IIC)",
  //       pre: "We are happy to announce that Karnavati University has achieved 4 Star Ranking for its continuous efforts to promote Innovation",
  //       time: "January 25, 2023",
  //       country: "United States",
  //       alt: "UX Research Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award4,
  //       data: "21",
  //       month: "May, 2022",
  //       hed: "Top Design Institute of India by Competition Success Review (CSR)",
  //       pre: "Unitedworld Institute of Design, Karnavati University, Gandhinagar, Gujarat has been awarded the CSR Top Design Institute of India by the Chairman of the acclaimed Competition Success Review (CSR), Mr. SK Sachdeva.",
  //       time: "May 21, 2022",
  //       country: "Canada",
  //       alt: "Game Development Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award6,
  //       data: "13",
  //       month: "may , 2019",
  //       hed: "Excellence in Education Award",
  //       pre: "Shri Ritesh Hada, President, Karnavati University, was felicitated by Shri Vijay Rupani, Hon’ble Chief Minister of Gujarat, for extraordinarily exceptional work done",
  //       time: "13 may,2019",
  //       country: "Australia",
  //       alt: "Cloud Security Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award5,
  //       data: "27",
  //       month: "aprail 2019",
  //       hed: "UWSB ranks 24th (A++ Category)",
  //       pre: "Unitedworld School of Business (UWSB) – Ahmedabad Campus ranked 24th (A++ Category) 8th (Industry Interface) 10th (RoI) 10th (West Zone) 10th (Infrastructure) in the annual survey of Siliconindia Education What if not IIMs: B- Schools Survey 2017",
  //       time: "April 27, 2019",
  //       country: "Germany",
  //       alt: "Social Media Marketing Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award3,
  //       data: "2",
  //       month: "may 2019",
  //       hed: "UID – Best Private Design College in India",
  //       pre: "Unitedworld Institute of Design (UID) has been conferred with the award “Best Private Design College in India” during the National Education Excellence Awards organised by one of the leading Brand Management Consulting & Research firm in India, World Business...",
  //       time: "2 May 2019",
  //       country: "France",
  //       alt: "img",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  //   ourPlacedStudent: [
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I cannot forget my days at Quantum and can’t thank them enough for the wonderful friends that I have made there.",
  //       perImg: qankit,
  //       Name: "Ankit",
  //       work: "Publicis sapient",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Thanks to the technical skills I got at Quantum, I am today working in one of the top IT companies in India.",
  //       perImg: qarchit,
  //       Name: "Archit Madan",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         " Quantum has not only given me education but has also helped me to build my personality, which helped me to shape my career.",
  //       perImg: qbhupendra,
  //       Name: "Bhupender Pal ",
  //       work: "Oracle",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I number of companies that visit Quantum for placements is simply amazing. All the students get ample chance to get placed.",
  //       perImg: qdipayan,
  //       Name: "Dipayan Bhowmik",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Being at Quantum was one of the greatest experiences of my life. I learnt a lot of new things to grow my career.   ",
  //       perImg: qkartikey,
  //       Name: "Karthikey Chauhan",
  //       work: "Capgemini",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "It was an Overwhelming experience. I still miss the moments I spent at Quantum. I want to study once more at Quantum. ",
  //       perImg: qmayank,
  //       Name: "Mayank Tayal",
  //       work: "NIIT Technologies",
  //     },
  //   ],
  //   applicationDeadline: "1 july",
  //   noScholarships: "22",
  //   amount: "variable",
  //   internationalStudents: "yes",
  //   scholarshipLink: "www.aapadhe.in",
  //   address: "Gujarat",
  //   loanBNK:
  //     "QUANTUM UNIVERSITY IS UGC APPROVED UNIVERSITY, SO ONCE YOU RECEIVE YOUR JOINING LETTER,YOU CAN CONTACT YOUR BANK FOR EDUCATION LOANS. FOR MORE",
  //   email: "karnawati@offical.com",
  //   phone: "+919257767661",
  //   hiringPartner: [
  //     karah1,
  //     karah2,
  //     karah3,
  //     karah4,
  //     karah5,
  //     karah6,
  //     karah7,
  //     karah8,
  //     karah9,
  //     karah10,
  //     karah11,
  //     karah12,
  //     karah4,
  //     karah7,
  //   ],
  //   studentEnroll: "125,300",
  //   registeredInstructor: "300",
  //   successRate: "100",
  //   successStory: [
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/shu-kJkwKUk?si=LeZ3F-ppgBI8EP3d",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //   ],
  //   doubtSection: [
  //     {
  //       question: "Is Quantum University good?",
  //       answer1:
  //         "It has featured among the top 10 engineering colleges in uttarakhand. Over the past few years, Quantum University has won the title of best placement college in dehradun,",
  //       answer2:
  //         "due to its latest and innovative teaching-learning methodology. Several reputed companies visit the campus every year for recruiting Q-mates.",
  //     },
  //     {
  //       question: "Is Quantum University degree valid?",
  //       answer1:
  //         "Yes, the degree offered by Quantum University is valid. Quantum University is recognised by the University Grants Commission, which is a statutory body of the Government of India.",
  //     },
  //     {
  //       question: "How old is quantum university?",
  //       answer1:
  //         "History. Quantum University Roorkee was founded in 2017 by industrialist Shri Shyam Sunder Goyal. The core programs initially offered by the college were in the disciplines of Engineering and Management. In 2018 the university started programs in Graduate Studies.",
  //     },
  //     {
  //       question: "Is quantum is UGC approved?",
  //       answer1:
  //         "Quantum University is a private university in Roorkee, Uttarakhand, India. It was established in 2008 and is approved by the University Grants Commission which is a statutory body of the Government of India. Quantum University has both government recognition and professional accreditation.",
  //     },
  //     {
  //       question: "Is Quantum University good for placement?",
  //       answer1:
  //         "Placements: Yes, arrange for placement, and more than 100 students got placed this year alone. A total of 900 students received placement offers from Quantum University in various companies and MNCs.",
  //     },
  //   ],
  //   blogNews: [
  //     {
  //       img: marwadie1,
  //       data: "6",
  //       icon: <FaCalendarAlt />,
  //       month: "6th November 2024",
  //       hed: "Internship Workshop.",
  //       pre: "InternEdge Internship Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Belgium",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie2,
  //       data: "30",
  //       icon: <FaCalendarAlt />,
  //       month: "30th December 2024",
  //       hed: "Job Fair.",
  //       pre: "CareerPro Job Fair for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Austria",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie1,
  //       data: "10",
  //       icon: <FaCalendarAlt />,
  //       month: "10th January 2025",
  //       hed: "Career Development Workshop.",
  //       pre: "FutureCare Career Development Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Norway",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  // };
  const uni = {
    Color: "bg-[#0065B5]",
    Text: "bg-[#0065B5]",
    id: "haridwar-university",
    university: "Haridwar University",
    image: [mainbanner, mainbanner, mainbanner],
    courses: [
      {
        name: "B.tech(Hons.)",
        fee: "₹ 1,27,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: ["CSE/CE", "EEE/ME"],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im7, // image source for BBA LLB
        description: "Description B.tech(Hons.) course goes here.",
      },
      {
        name: "B.Sc Computer Science",
        fee: "₹ 82,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: ["Computer Science"],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im4, // image source for B.Tech
        description: "Description for B.Sc course goes here.",
      },
      {
        name: "M.Tech",
        fee: "₹ 78,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im8, // image source for M.Tech
        description: "Description for M.Tech course goes here.",
      },
      {
        name: "BCA",
        fee: "₹ 82,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "According to merit and need-based.",
        specialisation: ["Computer Applications"],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im4, // image source for BCA
        description: "Description for BCA course goes here.",
      },
      {
        name: "MCA",
        fee: "₹ 92,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: ["Computer Applications"],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im5, // image source for MCA
        description: "Description for MCA course goes here.",
      },
      {
        name: "BBA",
        fee: "₹ 82,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: ["general BBA"],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im1, // image source for MCA
        description: "Description for BBA course goes here.",
      },
      {
        name: "MBA",
        fee: "₹ 2,49,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Please apply the  form and get to know all the details",
        ],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im2, // image source for MCA
        description: "Description for MBA course goes here.",
      },
      {
        name: "B.com (Hons)",
        fee: "₹ 67,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Please apply the  form and get to know all the details",
        ],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im7, // image source for MCA
        description: "Description for B.com course goes here.",
      },
      {
        name: "Bachelors in Hotel Management",
        fee: "₹ 87,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Please apply the  form and get to know all the details",
        ],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im8, // image source for MCA
        description: "Description for hotel management course goes here.",
      },
      {
        name: "Bachelors in Agriculture",
        fee: "₹ 87,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          " Nutrition & Dietetics",
          "Food Technology",
          "Microbiology",
          "Animation & Multimedia"
        ],
        location: "Roorkee Dehra Dun(Uttarakhand)",
        approval: true,
        image: im6, // image source for MCA
        description: "Description for Bachelors in Agriculture  course goes here.",
      },
    ],
    event: [
      {
        img: im1,
        data: "18",
        month: "March, 2023",
        hed: " Basic UI & UX Design for new development",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im2,
        data: "20",
        month: "March, 2023",
        hed: " Digital Education Market Briefing: Minnesota 2023",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im3,
        data: "22",
        month: "March, 2023",
        hed: " Learning Network Webinars for Music Teachers",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im6,
        data: "22",
        month: "March, 2023",
        hed: "  Next-Gen Higher Education Students Have Arrived?",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im7,
        data: "24",
        month: "March, 2023",
        hed: "  Digital Art & 3D Model – a future for film company",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im8,
        data: "26",
        month: "March, 2023",
        hed: "   Conscious Discipline Summer Institute",
        pre: "Seamlessly visualize quality ellectual capital without superior collaboration and idea tically",
        time: "3:30 pm - 4:30 pm",
        country: "United Kingdome",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
    ourPlacedStudent: [
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " feels like a dream come true, Being Nepalee, learning about other's cultures was always important to me. Haridwar University (Formerly Roorkee College of Engineering) is the best.",
        perImg: anshubudha,
        Name: "Anshu Budha",
        work: "Infosys",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " Fabulous and rewarding is how I would describe my past two years at Haridwar University (Formerly Roorkee College of Engineering).",
        perImg: chandansharma,
        Name: "Chandan Sharma",
        work: "Gold Plus",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " I think the facilities, environment and the resources here are much better than the other college.",
        perImg: sagargautam,
        Name: "Sagar Gautam",
        work: "HCL",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " feels like a dream come true, I like the friendly atmosphere here and the fact the teachers are really close to the students.",
        perImg: shyamnarayan,
        Name: "Shyam Narayan",
        work: "Wippro",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "  I like the friendly atmosphere here and the fact the teachers are really close to the students.",
        perImg: shrutisaloni,
        Name: "Shruti Shree",
        work: "Capegemini",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " Learning about other's cultures was always important to me. Haridwar University (Formerly Roorkee College of Engineering) is the best.",
        perImg: rachitkaushik,
        Name: "Rachit Kaushik",
        work: "ischools Connect",
      },
    ],
    applicationDeadline: "1 july",
    noScholarships: "22",
    amount: "variable",
    internationalStudents: "yes",
    scholarshipLink: "www.aapadhe.in",
    loanBNK:
      "Haridwar University HAS COORDINATED WITH PUNJAB & SIND BANK TO FACILITATE ITS STUDENTS WHO ARE INTERESTED IN AVAILING EDUCATION LOANS.",
    email: "hu@offical.com",
    phone: "+919875872324",
    hiringPartner: [
      pacifich1,
    hy2,
      hy3,
      hy4,
      hy5,
      hy6,
      hy7,
      hy8,
      hy9,
      hy5,
      hy2,
      hy4,
      hy3,
      pacifich8,
      pacifich10,
    ],
    studentEnroll: "125,300",
    registeredInstructor: "300",
    successRate: "100",
    address: "Roorkee, Uttarakhand",
    successStory: [
      "https://www.youtube.com/embed/mhZmP5GDiOk?si=vPp0GiiVcxNGE510",
      "https://www.youtube.com/embed/D4-SEOt7xCo?si=jx4H26JLdc6F0TxQ",
      "https://www.youtube.com/embed/I7DbmdOQt6o?si=3A12yB1lPkEyZrMn",
      "https://www.youtube.com/embed/QjHqeQOHM4I?si=f9uyIg2Fj68n-tAC",
      "https://www.youtube.com/embed/mhZmP5GDiOk?si=vPp0GiiVcxNGE510",
      "https://www.youtube.com/embed/D4-SEOt7xCo?si=jx4H26JLdc6F0TxQ",
      "https://www.youtube.com/embed/I7DbmdOQt6o?si=3A12yB1lPkEyZrMn",
      "https://www.youtube.com/embed/QjHqeQOHM4I?si=f9uyIg2Fj68n-tAC",
    ],
    doubtSection: [
      {
        question: "Is Haridwar University good?",
        answer1:
          "It has featured among the top 10 engineering colleges in Karnataka. Over the past few years, Haridwar University has won the title of best placement college in Bangalore, due to its latest and innovative teaching-learning methodology. Several reputed companies visit the campus every year for recruiting Haridwar University students.",
        answer2: null,
      },
      {
        question: "Is Haridwar University degree valid?",
        answer1:
          "Yes, the degree offered by Haridwar University is valid. Haridwar University is recognized by the University Grants Commission, which is a statutory body of the Government of India.",
        answer2: null,
      },
      {
        question: "How old is Haridwar University?",
        answer1:
          "History. Haridwar University was founded in 1990 by Dr. Chenraj Roychand. The core programs initially offered by the university were in the disciplines of Engineering and Management. Over the years, Haridwar University has expanded its offerings to include a wide range of undergraduate and postgraduate programs.",
        answer2: null,
      },
      {
        question: "Is Haridwar University UGC approved?",
        answer1:
          "Haridwar University is a deemed-to-be university located in Bangalore, Karnataka, India. It was declared a deemed-to-be university under section 3 of the UGC Act, 1956, in 2009. Haridwar University has both government recognition and professional accreditation.",
        answer2: null,
      },
      {
        question: "Is Haridwar University good for placement?",
        answer1:
          "Placements: Yes, Haridwar University arranges for placement, and more than 100 students got placed this year alone. A total of 900 students received placement offers from Haridwar University in various companies and MNCs.",
        answer2: null,
      },
    ],
    blogNews: [
      {
        img: marwadie1,
        data: "5",
        icon: <FaCalendarAlt />,
        month: "5th March 2025",
        hed: "Industry Networking Event.",
        pre: "IndustryConnect Industry Networking Event for Students of Quantum University",
        txt: "Read More",
        country: "Denmark",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie3,
        data: "23",
        icon: <FaCalendarAlt />,
        month: "23rd April 2025",
        hed: "Career Expo.",
        pre: "CareerEdge Career Expo for Students of Quantum University",
        txt: "Read More",
        country: "Finland",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie5,
        data: "12",
        icon: <FaCalendarAlt />,
        month: "12th May 2025",
        hed: "Job Recruitment Drive.",
        pre: "RecruitPro Job Recruitment Drive for Students of Quantum University",
        txt: "Read More",
        country: "Ireland",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <Router>
        
        <Navbar uni={uni}></Navbar>
        <Routes>
  
          <Route path="/" element={<UniversityPage uni={uni} />} />
          <Route path="/courses" element={<UniversityPage uni={uni} />} />
          <Route path="/apply-now" element={<UniversityPage uni={uni} />} />
          <Route path="/scholarship" element={<UniversityPage uni={uni} />} />
          {/* Route for the enquiry form */}
          <Route path="/enquiryform" element={<Enquriyform />} />
          <Route path="/blogpost2" element={<BlogPost2 />} />
          <Route path="/blogpost1" element={<BlogPost1 />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer uni={uni} />
        <Disclaimer />

        <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
          <div className="p-3 bg-gray-300 mb-3 rounded-full">
            <a href="tel:+919875872324" >
              <IoCall className="text-blue-500" />
            </a>
          </div>
          <div className="p-3 bg-gray-300 mb-2 rounded-full">
            <a href="https://wa.me/+919988776655" >
              <IoLogoWhatsapp className="text-green-500" />
            </a>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
